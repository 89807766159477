import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { debugLog } from 'core/utils'
import { useNavigateTo } from 'hooks/navigation.hook'
import AuthProvider from 'data/providers/auth.provider'
import routes from 'routes'

export const GuestMiddleware = ({ children }) => {
  const navigateTo = useNavigateTo()

  useEffect(() => {
    if (AuthProvider.isAuthenticated()) {
      debugLog('User is authenticated')
      navigateTo(routes.home.path)
    }
  }, [navigateTo])

  return <React.Fragment>{children}</React.Fragment>
}

GuestMiddleware.propTypes = {
  children: PropTypes.element.isRequired,
}
