import React from 'react'

import PropTypes from 'prop-types'
import { AuthProviderContext } from 'components/providers/auth.provider'
import PagesLayout from './PagesLayout'

const UserGuestScaffold = ({ children }) => {
  return (
    <PagesLayout>
      <AuthProviderContext onlyIfNotAuthenticated>{children}</AuthProviderContext>
    </PagesLayout>
  )
}

export default UserGuestScaffold

UserGuestScaffold.propTypes = {
  children: PropTypes.element,
}
