import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/dashboard.view'))
const EditProfileView = React.lazy(() => import('views/profile/edit_profile.view'))
const NewProfileView = React.lazy(() => import('views/profile/new_profile.view'))
const ProfileView = React.lazy(() => import('views/profile/profile.view'))
const InvoicesView = React.lazy(() => import('views/invoices/invoices.view'))
const InvoiceView = React.lazy(() => import('views/invoices/invoice.view'))
const TablesView = React.lazy(() => import('views/tables/tables.view'))
const StatisticsHistoricView = React.lazy(() => import('views/statistics/historic/historic.view'))
const StatisticsRecycleView = React.lazy(() => import('views/statistics/recycle/recycle.view'))
const StatisticsCategoryView = React.lazy(
  () => import('views/statistics/categories/statistics_category.view'),
)
const StatisticsComparisonView = React.lazy(
  () => import('views/statistics/comparison/comparison.view'),
)
const QueryParamsProvider = React.lazy(() => import('components/providers/query_params.provider'))

const routes = {
  // Dashboard Routes
  home: { path: '/', exact: true, name: 'route_home' },
  welcome: { path: '/welcome', name: 'route_welcome', element: Dashboard },
  // Invoices
  invoices: {
    path: '/invoices',
    name: 'route_invoices',
    element: InvoicesView,
    parent: QueryParamsProvider,
  },
  invoice: {
    path: '/invoices',
    param: '/:client/:code',
    name: 'route_invoice',
    element: InvoiceView,
  },
  // statistics
  statisticsHistoric: {
    path: '/statistics/historic',
    name: 'route_statistics_historic',
    element: StatisticsHistoricView,
    parent: QueryParamsProvider,
  },
  statisticsComparison: {
    path: '/statistics/comparison',
    name: 'route_statistics_comparison',
    element: StatisticsComparisonView,
    parent: QueryParamsProvider,
  },
  statisticsRecycle: {
    path: '/statistics/recycle',
    name: 'route_statistics_recycle',
    element: StatisticsRecycleView,
    parent: QueryParamsProvider,
  },
  statisticsCategory: {
    path: '/statistics/historic',
    name: 'route_statistics_historic',
    param: '/:category',
    element: StatisticsCategoryView,
    parent: QueryParamsProvider,
  },
  // Profile
  profile: { path: '/profile', name: 'route_profile', element: ProfileView },
  newProfile: {
    path: '/profile/new',
    name: 'route_new_profile',
    element: NewProfileView,
  },
  editProfile: {
    path: '/profile',
    param: '/:id',
    name: 'route_edit_profile',
    element: EditProfileView,
  },
  createUser: { path: '/create/user', name: 'route_create_user', element: EditProfileView },
  tables: {
    path: '/tables',
    name: 'route_tables',
    element: TablesView,
    parent: QueryParamsProvider,
  },
}

export const routesExclusiveAdmin = ['route_tables']

export default routes
