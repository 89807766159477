import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useThemeProvider } from 'components/providers/theme.provider'
import { toastDefaultDuration, toastDefaultPosition } from 'core/constants/config.constants'
import PropTypes from 'prop-types'

const MasterLayout = ({ children }) => {
  const { themeMode } = useThemeProvider()
  return (
    <React.Fragment>
      {children}
      <ToastContainer
        position={toastDefaultPosition}
        autoClose={toastDefaultDuration}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme={themeMode}
      />
    </React.Fragment>
  )
}

export default MasterLayout

MasterLayout.propTypes = {
  children: PropTypes.element,
}
