import React from 'react'

import PropTypes from 'prop-types'
import MasterLayout from './MasterLayout'

const PagesLayout = ({ children }) => {
  return (
    <MasterLayout>
      <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
        {children}
      </div>
    </MasterLayout>
  )
}

export default PagesLayout

PagesLayout.propTypes = {
  children: PropTypes.element,
}
