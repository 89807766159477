import axios, { CanceledError } from 'axios'
import { debugLog } from 'core/utils'
import { ExpectedError, LaravelError } from 'core/utils/exceptions.utils'
import AuthProvider from 'data/providers/auth.provider.js'

class HTTPService {
  constructor() {
    // for httpOnly cookies
    axios.defaults.withCredentials = true

    this.cancelTokens = {}
    this.cancelToken = axios.CancelToken

    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Application-Signature': process.env.REACT_APP_API_KEY,
        // this is not secure for XSS attacks
        // Authorization: 'Bearer ' + localStorage.getItem('authToken'),
      },
    })
  }

  // cancel a request
  cancelRequest = (request) => {
    if (this.cancelTokens[request]) {
      debugLog('Canceling request: ', request)
      this.cancelTokens[request].cancel('Request canceled')
      delete this.cancelTokens[request]
    }
  }

  async getBlob(path) {
    return this.get(path, { responseType: 'blob' })
  }

  async get(path, additionalRequestConfigs = {}) {
    try {
      this.cancelTokens[path] = this.cancelToken.source()
      const response = await this.api.get(`${path}`, {
        ...additionalRequestConfigs,
        cancelToken: this.cancelTokens[path].token,
      })
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
  }

  async post(path, data, additionalRequestConfigs = {}) {
    try {
      const response = await this.api.post(`${path}`, data, additionalRequestConfigs)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
  }

  async delete(path, additionalRequestConfigs = {}) {
    try {
      const response = await this.api.delete(`${path}`, additionalRequestConfigs)
      return this.handleResponse(response)
    } catch (error) {
      return this.handleErrors(error)
    }
  }

  async handleResponse(response) {
    if (response.status !== 200) {
      debugLog('HTTPService not handled error: ', response)
      // throw the error as has it is
      throw response
    }
    return response
  }

  async handleErrors(error) {
    let response = error.response

    // if is canceled is ok
    if (axios.isCancel(error)) {
      debugLog('Request canceled', error.message)
      throw new CanceledError('Request canceled')
    }

    // forbidden error
    if (response.status === 403) {
      // If already on login page do not redirect, the login page will handle the error
      if (window.location.pathname === '/login') {
        throw error
      }
      window.location.href = '/forbidden'
    }

    if (response.status === 401) {
      // If already on login or reset page do not redirect, the login page will handle the error
      if (window.location.pathname === '/login' || window.location.pathname === '/reset') {
        throw new ExpectedError('invalid_credentials', response.status)
      }
      AuthProvider.logout(true)
      // Redirect to login
      window.location.href = '/login'
      return
    }
    // Handle 429 request errors (Too many requests)
    if (response.status === 429) {
      if (window.location.pathname === '/login') {
        throw new ExpectedError('too_many_requests', response.status)
      }
      throw error
    }
    // Handle 452 custom messages errors
    if (response.status === 452) {
      throw new ExpectedError(response.data.message, response.status)
    }
    if (response.status === 404) {
      debugLog('HTTPService 404 error: ', response)
      if (process.env.REACT_APP_APP_ENV !== 'development') {
        window.location.href = '/404'
        return
      }
      throw error
    }
    // Handle 422 request errors
    if (response.status === 422) {
      let laravelErrors = response.data.errors
      throw new LaravelError('Request validation error', response.status, laravelErrors)
    }
    throw error
  }
}

export const apiService = new HTTPService()
