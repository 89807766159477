import React, { createContext, useState } from 'react'

import PropTypes from 'prop-types'
import i18next from 'i18next'

export const keyStorageConfigs = process.env.REACT_APP_SETTINGS_STORAGE_KEY

export const initialConfigs = {
  sideMenuOpen: true,
  language: i18next.language,
}

export const ConfigContext = createContext()

// get configs from local storage
var getStoredConfigs = function () {
  let configs = JSON.parse(localStorage.getItem(keyStorageConfigs))

  return typeof window !== 'undefined' && (configs ?? initialConfigs)
}

// Persist configs in local storage
var setStoredConfigs = function (configs) {
  if (typeof window === 'undefined') {
    return {}
  }
  localStorage.setItem(keyStorageConfigs, JSON.stringify(configs))
}

export const ConfigProvider = ({ children }) => {
  const [configs, setConfigs] = useState(getStoredConfigs() ?? initialConfigs)

  const setConfig = (element) => {
    const newConfigs = { ...configs, [element.key]: element.value }
    setConfigs(newConfigs)
    setStoredConfigs(newConfigs)
  }

  return <ConfigContext.Provider value={{ configs, setConfig }}>{children}</ConfigContext.Provider>
}

ConfigProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
