import { debugErrorLog } from 'core/utils'
import { notifyError } from 'data/providers/versantweb.provider'
export class ErrorModel {
  message: string
  data?: any
  error?: any

  constructor(message, data, error) {
    this.message = message

    // Adjusting parameters if `error` is not provided
    if (!error) {
      error = data
      data = null
    }
    this.data = data // Additional data relevant to the error context
    this.error = {
      message: error.message, // Standard error message
      stack: error.stack, // Stack trace for debugging
      name: error.name, // Error name (e.g., TypeError)
    }
    notifyError(this.toJSON())
    debugErrorLog(error)
  }

  toJSON() {
    return JSON.stringify({
      message: this.message,
      data: this.data,
      error: this.error,
    })
  }
}
