import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useColorModes } from '@coreui/react'
import { keyStorageTheme } from 'core/constants/config.constants'
import { debugLog } from 'core/utils'

const ThemeContext = createContext()

//  Provider component that wraps your app and makes
export const useThemeProvider = () => useContext(ThemeContext)

//  Provider component that wraps your component to provide user data
export const ThemeProvider = ({ children }) => {
  // represents the real theme
  const [theme, _setTheme] = useState(undefined)
  // dark, light, or auto
  const [themeMode, setThemeMode] = useState(undefined)
  const { colorMode, isColorModeSet, setColorMode } = useColorModes(keyStorageTheme)

  const defaultOSThemeIsDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches

  // check the system theme and request to set it
  const setThemeBasedOnOSTheme = () => {
    if (defaultOSThemeIsDark) {
      setTheme('dark')
    } else {
      setTheme('light')
    }
  }

  const isDark = () => theme === 'dark'

  // set the theme and theme mode based on dark, light or auto modes
  const setTheme = (newThemeMode) => {
    if (newThemeMode !== 'dark' && newThemeMode !== 'light' && newThemeMode !== 'auto') {
      throw new Error(`Invalid theme: ${newThemeMode}`)
    }
    // if the Theme Mode is auto
    if (newThemeMode === 'auto') {
      // we set the the theme mode to auto also
      setThemeMode(newThemeMode)
      // and call the function to verify the system theme
      setThemeBasedOnOSTheme()
      return
    } else {
      setThemeMode(newThemeMode)
    }
    setColorMode(newThemeMode)
    _setTheme(newThemeMode)
  }

  useEffect(() => {
    // If user already has a preference stored
    if (isColorModeSet()) {
      debugLog('Color mode is set', colorMode)
      setTheme(colorMode)
      return
    }

    // If user don't have already has a preference stored
    // then we try to figure it out using the OS theme
    setThemeBasedOnOSTheme()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ThemeContext.Provider value={{ theme, themeMode, setThemeMode: setTheme, isDark }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
