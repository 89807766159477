// @ts-ignore
import Auth, { isValidAuth } from 'data/models/auth.model.ts'

// Key for storing authentication data in local storage
const STORAGE_KEY = process.env.REACT_APP_AUTH_STORAGE_KEY

class AuthStorageManager {
  // Save mange authentication data to be saved on local storage
  static save(authData): Auth | null {
    try {
      const validation = isValidAuth(authData)
      if (validation.isValid) {
        let auth = Auth.fromJSON(authData)
        this.persist(auth)
        return auth
      }
      throw new Error('Invalid authentication data' + validation.errors.join(', '))
    } catch (error) {
      return null
    }
  }

  // persist the authenticator data to local storage
  private static persist(authData: Auth): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(authData))
  }

  // Retrieve authentication data from local storage
  static get(): Auth | null {
    // this code allow to see all the items in the local storage
    // const items = {}
    // for (let i = 0; i < localStorage.length; i++) {
    //   const key = localStorage.key(i)
    //   items[key] = localStorage.getItem(key)
    // }
    // console.log('Auth Storage Manager: ', items)
    const authDataString = localStorage.getItem(STORAGE_KEY)
    if (authDataString) {
      return Auth.fromJSON(JSON.parse(authDataString))
    }
    return null
  }

  // Clear authentication data from local storage
  static clean(): void {
    localStorage.removeItem(STORAGE_KEY)
  }
}

export default AuthStorageManager
