import { ValidationResult } from 'core/models/validationResult.model'
import PropTypes from 'prop-types'

export interface AuthInterface {
  id: number
  email: string
  name: string
  surname: string
  description: string
  isAdmin: boolean
  isSuperUser: boolean
  active: boolean
  role: string
  createdAt: Date
}

class Auth implements AuthInterface {
  id: number
  email: string
  name: string
  surname: string
  description: string
  isAdmin: boolean
  isSuperUser: boolean
  active: boolean
  role: string
  createdAt: Date

  constructor(data: AuthInterface) {
    this.id = data.id
    this.email = data.email
    this.name = data.name
    this.surname = data.surname
    this.description = data.description
    this.isAdmin = data.isAdmin
    this.isSuperUser = data.isSuperUser
    this.active = data.active
    this.role = data.role
    this.createdAt = new Date(data.createdAt)
  }
  toJSON() {
    return {
      id: this.id,
      email: this.email,
      name: this.name,
      surname: this.surname,
      description: this.description,
      isAdmin: this.isAdmin,
      isSuperUser: this.isSuperUser,
      active: this.active,
      role: this.role,
      createdAt: this.createdAt.toISOString(),
    }
  }

  static fromJSON(json: AuthInterface): Auth {
    return new Auth(json)
  }

  updateFromObject(user) {
    this.id = user.id
    this.email = user.email
    this.name = user.name
    this.surname = user.surname
    this.description = user.description
    this.isAdmin = user.isAdmin
    this.isSuperUser = user.isSuperUser
    this.active = user.active
    this.role = user.role
    this.createdAt = user.createdAt
  }

  toString(): string {
    return `Auth: { id: ${this.id}, email: ${this.email}, name: ${this.name}, surname: ${
      this.surname
    }, description: ${this.description}, isAdmin: ${this.isAdmin}, isSuperUser: ${
      this.isSuperUser
    }, active: ${this.active}, role: ${this.role}, createdAt: ${this.createdAt.toISOString()} }`
  }
}

export default Auth

export const AuthShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isAdmin: PropTypes.string.isRequired,
  isSuperUser: PropTypes.string.isRequired,
  active: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
})

export function isValidAuth(data: any): ValidationResult {
  let errors: string[] = []

  if (typeof data.id !== 'number')
    errors.push('id must be a number, value: ' + data.id + ', type: ' + typeof data.id)
  if (typeof data.name !== 'string')
    errors.push('name must be a string, value: ' + data.name + ', type: ' + typeof data.name)
  if (typeof data.surname !== 'string')
    errors.push(
      'surname must be a string, value: ' + data.surname + ', type: ' + typeof data.surname,
    )
  if (typeof data.description !== 'string')
    errors.push(
      'description must be a string, value: ' +
        data.description +
        ', type: ' +
        typeof data.description,
    )
  if (typeof data.email !== 'string')
    errors.push('email must be a string, value: ' + data.email + ', type: ' + typeof data.email)
  if (typeof data.isAdmin !== 'boolean')
    errors.push(
      'isAdmin must be a boolean, value: ' + data.isAdmin + ', type: ' + typeof data.isAdmin,
    )
  if (typeof data.isSuperUser !== 'boolean')
    errors.push(
      'isSuperUser must be a boolean, value: ' +
        data.isSuperUser +
        ', type: ' +
        typeof data.isSuperUser,
    )
  if (typeof data.active !== 'boolean')
    errors.push('active must be a boolean, value: ' + data.active + ', type: ' + typeof data.active)
  if (typeof data.role !== 'string')
    errors.push('role must be a string, value: ' + data.role + ', type: ' + typeof data.role)
  if (typeof data.createdAt !== 'string')
    errors.push(
      'createdAt must be a string, value: ' + data.createdAt + ', type: ' + typeof data.createdAt,
    )

  return {
    isValid: errors.length === 0,
    errors,
  }
}
