import { useContext } from 'react'
import { ConfigContext, initialConfigs } from 'layout/providers/AppProvider'
import { useThemeProvider } from 'components/providers/theme.provider'
export const keySideMenuOpen = 'sideMenuOpen'
export const keyAppLanguage = 'language'

// Custom hook to get and set configs that will be used in components
export const useConfigs = (initKey) => {
  const { setThemeMode } = useThemeProvider()
  if (initKey && initKey in initialConfigs === false) {
    throw new Error(
      `Key: ${initKey} not found in initialConfigs, please use one of: ${Object.keys(
        initialConfigs,
      )}`,
    )
  }

  const { configs, setConfig } = useContext(ConfigContext)

  var getConfig = function (key) {
    if (initKey) {
      return configs[initKey] ?? initialConfigs[initKey]
    }
    Object.keys(configs).forEach(function (stateKey) {
      if (stateKey === key) {
        return configs[stateKey]
      }
    })
    return initialConfigs[key]
  }

  const _setConfig = (newValue) => {
    let value = initKey ? newValue : newValue.value
    let key = initKey ? initKey : newValue.key
    setConfig({ key, value })
  }

  const resetConfigs = () => {
    localStorage.clear()
    Object.keys(configs).forEach(function (stateKey) {
      setConfig({ key: stateKey, value: initialConfigs[stateKey] })
    })
    setThemeMode('auto')
  }

  if (initKey) {
    return [getConfig(), _setConfig, resetConfigs]
  }

  return [getConfig, _setConfig, resetConfigs]
}
