import { useNavigate } from 'react-router-dom'

// just because like that the import is easily recognized by vscode
export function useGoBack(steps) {
  const navigate = useNavigate()

  if (steps != null) return navigate(steps)

  return (steps = -1) => navigate(steps)
}

export function useNavigateTo(path) {
  const navigate = useNavigate()

  if (path) navigate(path)

  return (path) => navigate(path)
}
