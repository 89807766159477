import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AuthProvider from 'data/providers/auth.provider'
import { AuthenticatedMiddleware } from 'core/middlewares/authenticated.middleware'
import { GuestMiddleware } from 'core/middlewares/guest.middleware'
import { useNavigateTo } from 'hooks/navigation.hook'

const AuthContext = createContext()

//  Provider component that wraps your app and makes
export const useAuth = () => useContext(AuthContext)

//  Provider component that wraps your component to provide user data
export const AuthProviderContext = (props) => {
  const [auth, setAuth] = useState(AuthProvider.user)
  const navigate = useNavigateTo()

  // Make login request
  const login = async (data) => {
    let currentAuth = AuthProvider.user
    if (currentAuth !== null) return setAuth(currentAuth)
    await AuthProvider.login(data).then((auth) => {
      setAuth(auth)
      navigate('/')
    })
  }

  // Request a new password by email
  const updateAuth = async (newData) => {
    auth.updateFromObject({ ...auth, ...newData })
    setAuth(AuthProvider.authUpdated(auth))
    return auth
  }

  // Request a new password by email
  const recoverPassword = async (data) => {
    return AuthProvider.recoverPassword(data)
  }

  // Setting the new password and make login
  const resetPassword = async (data) => {
    return AuthProvider.resetPassword(data).then(async () => {
      return await login(data)
    })
  }

  // Make logout request
  const logout = async () => {
    let currentAuth = AuthProvider.user
    if (currentAuth === null) return setAuth(null)
    await AuthProvider.logout().then(() => {
      setAuth(null)
      navigate('/login')
    })
  }

  // Check if the user is authenticated or not for each time that the navigate changes
  useEffect(() => {
    if (props.onlyIfAuthenticated && auth === null) return navigate('/login')
    if (props.onlyIfNotAuthenticated && auth?.email !== undefined) return navigate('/')
  }, [navigate]) // eslint-disable-line

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        logout,
        login,
        recoverPassword,
        resetPassword,
        AuthProvider,
        updateAuth,
      }}
    >
      <React.Fragment>
        {/* If is routes only for authenticated users */}
        {props.onlyIfAuthenticated && !!auth && (
          <AuthenticatedMiddleware>{props.children}</AuthenticatedMiddleware>
        )}

        {/* If is routes only for not authenticated users */}
        {props.onlyIfNotAuthenticated && !auth && (
          <GuestMiddleware>{props.children}</GuestMiddleware>
        )}

        {/* If is routes for all users */}
        {!props.onlyIfAuthenticated && !props.onlyIfNotAuthenticated && props.children}
      </React.Fragment>
    </AuthContext.Provider>
  )
}

AuthProviderContext.propTypes = {
  children: PropTypes.element.isRequired,
  onlyIfAuthenticated: PropTypes.bool,
  onlyIfNotAuthenticated: PropTypes.bool,
}
