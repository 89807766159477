import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { debugLog } from 'core/utils'
import { useNavigateTo } from 'hooks/navigation.hook'
import AuthProvider from 'data/providers/auth.provider'

export const AuthenticatedMiddleware = ({ children }) => {
  const navigateTo = useNavigateTo()

  useEffect(() => {
    if (!AuthProvider.isAuthenticated()) {
      debugLog('User is not authenticated')
      navigateTo('/login')
    }
  }, [navigateTo])

  return <React.Fragment>{children}</React.Fragment>
}

AuthenticatedMiddleware.propTypes = {
  children: PropTypes.element.isRequired,
}
