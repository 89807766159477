import { defaultLocaleDateString } from 'core/constants/config.constants'
import { ErrorModel } from 'core/models/ErrorModel.model.ts'

function sort(list, ascending = true) {
  if (typeof ascending === 'string') ascending = ascending === 'asc'
  if (ascending) return list.sort((a, b) => a - b)
  return list.sort((a, b) => b - a)
}

// Returns if a var is not null and is a number
function isNumberNotNull(n) {
  return typeof n === 'number' && n !== null
}

function formatToSwissFormat(number, forceDecimal = true) {
  // Logic to format the number to Swiss format
  try {
    number = ensureNumber(number)
    // Determine if the number is an integer or has decimal places
    const hasDecimal = number % 1 !== 0

    // Define the options for the number format
    const options = {
      minimumFractionDigits: hasDecimal || forceDecimal ? 2 : 0, // Add .00 only if there are decimal values
      maximumFractionDigits: 2, // Ensure no more than two digits after the decimal
    }
    // For example, add thousands separator and adjust decimal places
    return number === 0 ? '0.00' : number.toLocaleString(defaultLocaleDateString, options)
  } catch (error) {
    new ErrorModel('Error on formatToSwissFormat', error)
  }
}

export { isNumberNotNull, formatToSwissFormat, sort }

// Function that make sure that a variable is number or convert it to one if possible, otherwise throws an exception
function ensureNumber(input) {
  if (typeof input === 'number') {
    // If input is already a number, return it
    return input
  } else if (typeof input === 'string') {
    // Attempt to parse string to number
    const parsedNumber = parseFloat(input)
    if (!isNaN(parsedNumber)) {
      // Return parsed number if successful
      return parsedNumber
    } else {
      throw new Error(
        'Input must be a valid number or convertible string, ' + input + ' is not a valid number',
      )
    }
  } else {
    throw new Error('Input must be a number or string')
  }
}
