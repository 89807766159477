import {
  defaultFormatToHumanDateFormat,
  defaultLocaleDateString,
} from 'core/constants/config.constants'
import { debugErrorLog } from '.'

function today() {
  const today = new Date()
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }

  return today.toLocaleDateString(defaultLocaleDateString, options)
}

function isToday(date) {
  if (typeof date === 'string') {
    date = new Date(date)
  }
  const today = new Date()
  // Remove the time component from today's date
  const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate())
  // Remove the time component from the provided date
  const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate())
  // Compare the dates without time
  return todayWithoutTime.getTime() === dateWithoutTime.getTime()
}

function formatToHumanDate(dateString) {
  let year, month, day

  const dateParts = dateString.split(/[-/]/) // Split by dash or slash

  if (dateParts.length === 2) {
    if (`${dateParts[1]}`.length === 4) {
      year = parseInt(dateParts[1])
      month = parseInt(dateParts[0]) - 1 // Months are zero-based in JavaScript Date objects
    } else {
      month = parseInt(dateParts[1]) - 1 // Months are zero-based in JavaScript Date objects
      year = parseInt(dateParts[0])
    }
    day = 1
  } else {
    if (`${dateParts[2]}`.length === 4) {
      year = parseInt(dateParts[2])
      month = parseInt(dateParts[1]) - 1 // Months are zero-based in JavaScript Date objects
      day = parseInt(dateParts[0])
    } else {
      day = parseInt(dateParts[2])
      month = parseInt(dateParts[1]) - 1 // Months are zero-based in JavaScript Date objects
      year = parseInt(dateParts[0])
    }
  }
  const date = new Date(year, month, day)

  const options = { month: 'long', year: 'numeric' }
  const formattedDate = new Intl.DateTimeFormat(defaultFormatToHumanDateFormat, options).format(
    date,
  )

  return dateParts.length === 2
    ? formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
    : day + ' ' + formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
}

function formatDateStringToDMY(dateString, splitter = '.') {
  const { day, month, year } = formatDateString(dateString, splitter)

  // Return the date in d-m-Y format
  return `${day}${splitter}${month}${splitter}${year}`
}

function formatDateStringToMY(dateString, splitter = '.') {
  const { month, year } = formatDateString(dateString, splitter)

  // Return the date in d-m-Y format
  return `${month}${splitter}${year}`
}

function formatDateString(dateString, splitter = '.') {
  dateString = dateString.split(' ')[0] // Remove time part if present
  dateString = dateString.split('T')[0] // Remove time part if present

  // Regular expressions for different date formats
  const ymdFormat = /^\d{4}-\d{2}-\d{2}/ // Y-m-d format
  const dmyFormat = /^\d{2}-\d{2}-\d{4}/ // d-m-Y format

  let dateParts
  let day, month, year

  if (ymdFormat.test(dateString)) {
    // Y-m-d format
    dateParts = dateString.split(/[-\s]/)
    year = dateParts[0]
    month = dateParts[1]
    day = dateParts[2]
  } else if (dmyFormat.test(dateString)) {
    // d-m-Y format
    dateParts = dateString.split(/[-\s]/)
    day = dateParts[0]
    month = dateParts[1]
    year = dateParts[2]
  } else {
    throw new Error('Date format not recognized. Please use Y-m-d or d-m-Y format.')
  }

  // Return the date in d-m-Y format
  return { day, month, year }
}

function isDateTime(dateString) {
  try {
    // Regular expressions for different date formats
    const ymdFormat = /^\d{4}-\d{2}-\d{2}/ // Y-m-d format
    const dmyFormat = /^\d{2}-\d{2}-\d{4}/ // d-m-Y format
    return ymdFormat.test(dateString) || dmyFormat.test(dateString)
  } catch (error) {
    debugErrorLog('DateUtils isDateTime:', error)
  }
}

function stringToDate(dateString) {
  try {
    // Check if date string includes time part
    const includesTime = dateString.includes(' ')

    // Regular expressions for different date formats
    const ymdFormat = /^\d{4}-\d{2}-\d{2}/ // Y-m-d format
    const dmyFormat = /^\d{2}-\d{2}-\d{4}/ // d-m-Y format

    let dateParts
    let year,
      month,
      day,
      hours = 0,
      minutes = 0,
      seconds = 0

    if (ymdFormat.test(dateString)) {
      // Y-m-d format
      dateParts = dateString.split(/[-\s:]/)
      year = parseInt(dateParts[0], 10)
      month = parseInt(dateParts[1], 10) - 1 // JavaScript months are 0-based
      day = parseInt(dateParts[2], 10)
    } else if (dmyFormat.test(dateString)) {
      // d-m-Y format
      dateParts = dateString.split(/[-\s:]/)
      day = parseInt(dateParts[0], 10)
      month = parseInt(dateParts[1], 10) - 1 // JavaScript months are 0-based
      year = parseInt(dateParts[2], 10)
    } else {
      throw new Error('Date format not recognized. Please use Y-m-d or d-m-Y format.')
    }

    if (includesTime) {
      hours = parseInt(dateParts[3], 10)
      minutes = parseInt(dateParts[4], 10)
      seconds = parseInt(dateParts[5], 10)
    }
    return new Date(year, month, day, hours, minutes, seconds)
  } catch (error) {
    debugErrorLog('DateUtils stringToDate:', error)
    return null
  }
}

export {
  today,
  formatToHumanDate,
  stringToDate,
  isToday,
  formatDateStringToDMY,
  formatDateStringToMY,
  isDateTime,
}
