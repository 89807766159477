import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

//Initialize language system
import './i18n'
import Loading from 'components/loading'
import { ThemeProvider } from 'components/providers/theme.provider'
import { ConfigProvider } from 'layout/providers/AppProvider'
import UserGuestScaffold from 'layout/UserGuestScaffold'
const { version } = require('../package.json')
// Containers
const DashboardLayout = React.lazy(() => import('./layout/DashboardLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Register = React.lazy(() => import('./views/pages/register/Register'))
const ResetPassword = React.lazy(() => import('./views/pages/resetPassword/ResetPassword'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page403 = React.lazy(() => import('./views/pages/page403/Page403'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const loading = <Loading />

const App = () => {
  return (
    <ConfigProvider>
      <React.Fragment>
        <div id={`version_${version}`}></div>
        <ThemeProvider>
          <BrowserRouter>
            <Suspense fallback={loading}>
              {/* Routes that the Suspense will wait for */}
              <Routes>
                <Route
                  path="/login"
                  name="Login Page"
                  element={
                    <UserGuestScaffold>
                      <Login />
                    </UserGuestScaffold>
                  }
                />
                <Route
                  path="/reset"
                  name="Reset Password Page"
                  element={
                    <UserGuestScaffold>
                      <ResetPassword />
                    </UserGuestScaffold>
                  }
                />
                {/* <Route path="/register" name="Register Page" element={<Register />} /> */}
                <Route path="/404" name="Page 404" element={<Page404 />} />
                <Route path="/forbidden" name="Forbidden" element={<Page403 />} />
                <Route path="/500" name="Page 500" element={<Page500 />} />

                {/* Entry Point */}
                <Route path="*" name="Home" element={<DashboardLayout />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </ThemeProvider>
      </React.Fragment>
    </ConfigProvider>
  )
}

export default App
