import { staticClientsList } from 'core/constants/constants'
import { debugLog } from 'core/utils'

export const notifyError = async (errorMSG) => {
  try {
    debugLog(errorMSG)
    // Simulating API request delay using setTimeout
    await new Promise((resolve) => setTimeout(resolve, 500))
    return {
      data: staticClientsList,
    }
  } catch (error) {
    throw new Error('FATAL ERROR: Failed to send error message to the server', errorMSG, error)
  }
}
