export const staticUsers = [
  {
    id: 1234,
    email: 'olivier@obtic.ch',
    name: 'Oliver',
    surname: 'Biedermann',
    description: 'Obi Chavornay',
    active: true,
    authorizedManageMunicipalInformation: true,
  },
  {
    id: 12345,
    email: 'l.marchand@chavornay.ch',
    name: 'Laurence',
    surname: 'Marchand',
    description: 'Laurence Marchand',
    active: true,
    authorizedManageMunicipalInformation: false,
  },
  {
    id: 123456,
    email: 'jl.staeubli@chavornay.ch',
    name: 'Jean-Luc	',
    surname: 'Stäubli',
    description: 'Jean-Luc Stäubli',
    active: false,
    authorizedManageMunicipalInformation: true,
  },
  {
    id: 4321,
    email: 'otheruser@test.ch',
    name: 'Other',
    surname: 'User',
    description: 'This is a static user only for tests',
    active: true,
    authorizedManageMunicipalInformation: true,
  },
  {
    id: 12345,
    email: 'john.doe@example.com',
    name: 'John',
    surname: 'Doe',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    active: true,
    authorizedManageMunicipalInformation: false,
  },
  {
    id: 5678,
    email: 'jane.doe@example.com',
    name: 'Jane',
    surname: 'Doe',
    description: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    active: false,
    authorizedManageMunicipalInformation: true,
  },
  {
    id: 9012,
    email: 'test.user@example.com',
    name: 'Test',
    surname: 'User',
    description:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    active: true,
    authorizedManageMunicipalInformation: true,
  },
  {
    id: 3456,
    email: 'alice.smith@example.com',
    name: 'Alice',
    surname: 'Smith',
    description:
      'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
    active: true,
    authorizedManageMunicipalInformation: false,
  },
]

export const staticAuth = staticUsers[0]

export const staticClientsList = [
  {
    code: 'C15948',
    name: '2route suisse-section romande',
    tva: 'CHE-123.456.789',
    description:
      "Groupe pour l'accès aux informations de la société 2routes suisse-section romande",
  },
  {
    code: 'C10040',
    name: 'Chavornay',
    tva: 'CHE-107.463.703',
    description: "Groupe pour l'accès aux information de la Commune de Chavornay",
  },
  {
    code: 'C11040',
    name: 'Yverdon',
    tva: 'CHE-107.463.233',
    description: "Groupe pour l'accès aux information de la Commune de Yverdon",
  },
  {
    code: 'C11240',
    name: 'Bavois',
    tva: 'CHE-102.463.701',
    description: "Groupe pour l'accès aux information de la Commune de Bavois",
  },
  {
    code: 'C12040',
    name: 'Orbe',
    tva: 'CHE-101.463.704',
    description: "Groupe pour l'accès aux informations de la Commune de Orbe",
  },
  {
    code: 'C13040',
    name: 'Aigle',
    tva: 'CHE-108.463.799',
    description: "Groupe pour l'accès aux informations de la Commune de Aigle",
  },
  {
    code: 'C14040',
    name: 'Morges',
    tva: 'CHE-109.463.709',
    description: "Groupe pour l'accès aux informations de la Commune de Morges",
  },
]

export const staticClientBalances = [
  {
    code: 'C15948',
    name: '2route suisse-section romande',
    open: '0.00',
    creditNotes: '0.00',
  },
  {
    code: 'C10040',
    name: 'Chavornay',
    open: '26622.10',
    creditNotes: '1623.25',
  },
]

export const staticClientOpenBalances = [
  {
    code: 'C15948',
    name: '2route suisse-section romande',
    data: [],
  },
  {
    code: 'C10040',
    name: 'Chavornay',
    data: [
      {
        month: '02-2024',
        creditNotes: [
          {
            number: 'N9003',
            amount: '-1623.25',
          },
        ],
        invoices: [
          {
            number: 'F172148',
            amount: '26622.1',
          },
          {
            number: 'F172112',
            amount: '-2000',
          },
          {
            number: 'F172748',
            amount: '0',
          },
        ],
      },
      {
        month: '03-2023',
        invoices: [],
        creditNotes: [
          {
            number: 'N9003',
            amount: '-1623.25',
          },
        ],
      },
    ],
  },
]

export const staticInvoicesQuery = [
  {
    code: 'C15948',
    name: '2route suisse-section romande',
    data: [
      {
        month: '02-2024',
        creditNotes: [
          {
            number: 'N9003',
            amount: '-1623.25',
            state: 'completed',
          },
        ],
        invoices: [
          {
            number: 'F172148',
            amount: '26622.1',
            state: 'pendent',
          },
          {
            number: 'F172112',
            amount: '-2000',
            state: 'completed',
          },
          {
            number: 'F172748',
            amount: '0',
            state: 'completed',
          },
        ],
      },
      {
        month: '03-2023',
        invoices: [],
        creditNotes: [
          {
            number: 'N9003',
            amount: '-1623.25',
            state: 'completed',
          },
        ],
      },
    ],
  },
  {
    code: 'C10040',
    name: 'Chavornay',
    data: [],
  },
]

export const staticRecycleOrIncinerableStats = {
  categories: [
    { label: 'recycled', color: { r: 0, g: 216, b: 255 } },
    { label: 'incinerable', color: { r: 221, g: 27, b: 22 } },
  ],
  data: [
    { 2008: ['24.50', '75.50'] },
    { 2009: ['10.20', '89.80'] },
    { 2010: ['35.70', '64.30'] },
    { 2011: ['18.90', '81.10'] },
    { 2012: ['27.80', '72.20'] },
    { 2013: ['42.60', '57.40'] },
    { 2014: ['19.30', '80.70'] },
    { 2015: ['15.80', '84.20'] },
    { 2016: ['31.50', '68.50'] },
    { 2017: ['13.70', '86.30'] },
    { 2018: ['37.40', '62.60'] },
    { 2019: ['20.90', '79.10'] },
    { 2020: ['28.60', '71.40'] },
    { 2021: ['11.50', '88.50'] },
    { 2022: ['23.40', '76.60'] },
    { 2023: ['33.80', '66.20'] },
    { 2024: ['16.10', '83.90'] },
  ],
}

export const staticInvoiceArt6 = {
  art: '0006',
  description: 'Prestations - désapprovisionnement de déchetterie communale',
  items: [
    {
      date: '01.02.24',
      type: 'transp.',
      quantity: 1.0,
      unit: 'forfait',
      price: 10.0,
      vat: 'A1',
      total: 10.0,
    },
    {
      date: '02.02.24',
      type: 'transp.',
      quantity: 1.0,
      unit: 'forfait',
      price: 10.0,
      vat: 'A1',
      total: 10.0,
    },
    {
      date: '03.02.24',
      type: 'transp.',
      quantity: 1.0,
      unit: 'forfait',
      price: 10.0,
      vat: 'A1',
      total: 10.0,
    },
  ],
}

export const staticInvoiceArt7 = {
  art: '0007',
  description: 'Prestations - désapprovisionnement de déchets',
  items: [
    {
      date: '01.02.24',
      type: 'transp.',
      quantity: 1.5,
      unit: 'forfait',
      price: 15.0,
      vat: 'A2',
      total: 22.5,
    },
    {
      date: '02.02.24',
      type: 'transp.',
      quantity: 1.5,
      unit: 'forfait',
      price: 15.0,
      vat: 'A2',
      total: 22.5,
    },
    {
      date: '03.02.24',
      type: 'transp.',
      quantity: 1.5,
      unit: 'forfait',
      price: 15.0,
      vat: 'A2',
      total: 22.5,
    },
  ],
}

export const staticInvoiceArt8 = {
  art: '0008',
  description: 'Prestations - désapprovisionnement de déchets de recyclage',
  items: [
    {
      date: '01.02.24',
      type: 'transp.',
      quantity: 2.0,
      unit: 'forfait',
      price: 20.0,
      vat: 'A3',
      total: 40.0,
    },
    {
      date: '02.02.24',
      type: 'transp.',
      quantity: 2.0,
      unit: 'forfait',
      price: 20.0,
      vat: 'A3',
      total: 40.0,
    },
    {
      date: '03.02.24',
      type: 'transp.',
      quantity: 2.0,
      unit: 'forfait',
      price: 20.0,
      vat: 'A3',
      total: 40.0,
    },
  ],
}

export const staticInvoiceArt0 = {
  art: '0000',
  description: 'Rétrocession extraordinaire sur boues livrées 2021-2022',
  items: [
    {
      date: '30.04.23',
      type: 'transp.',
      quantity: 0.0,
      price: 1507.19,
      vat: 'A1',
      total: 0.0,
    },
  ],
}

export const staticInvoices = [
  {
    code: 'F172148',
    accountingDate: '01.02.24',
    treatmentPeriod: '02.24',
    subtotal: '27172.96',
    totalDiscounts: '2545.65',
    vat: '7.7% (A1)',
    rounding: '0.01',
    total: '26622.10',
    client: staticClientsList[1],
    items: [
      {
        art: staticInvoiceArt6.art,
        description: staticInvoiceArt6.description,
        items: [staticInvoiceArt6.items[0], staticInvoiceArt6.items[1]],
      },
      {
        art: staticInvoiceArt7.art,
        description: staticInvoiceArt7.description,
        items: [staticInvoiceArt7.items[1], staticInvoiceArt7.items[2]],
      },
      {
        art: staticInvoiceArt8.art,
        description: staticInvoiceArt8.description,
        items: [staticInvoiceArt8.items[0], staticInvoiceArt8.items[2]],
      },
    ],
  },
  {
    code: 'F172112',
    accountingDate: '01.03.24',
    treatmentPeriod: '02.24',
    subtotal: '27172.96',
    totalDiscounts: '2545.65',
    vat: '7.7% (A1)',
    rounding: '0.01',
    total: '26622.10',
    client: staticClientsList[1],
    items: [
      {
        art: staticInvoiceArt7.art,
        description: staticInvoiceArt7.description,
        items: [staticInvoiceArt7.items[0], staticInvoiceArt7.items[1]],
      },
      {
        art: staticInvoiceArt8.art,
        description: staticInvoiceArt8.description,
        items: [staticInvoiceArt8.items[0], staticInvoiceArt8.items[1]],
      },
    ],
  },
  {
    code: 'F172748',
    accountingDate: '28.03.24',
    treatmentPeriod: '02.24',
    subtotal: '27172.96',
    totalDiscounts: '2545.65',
    vat: '7.7% (A1)',
    rounding: '0.01',
    total: '26622.10',
    client: staticClientsList[1],
    items: [
      {
        art: staticInvoiceArt6.art,
        description: staticInvoiceArt6.description,
        items: [staticInvoiceArt6.items[1], staticInvoiceArt6.items[2]],
      },
      {
        art: staticInvoiceArt8.art,
        description: staticInvoiceArt8.description,
        items: [staticInvoiceArt8.items[1], staticInvoiceArt8.items[2]],
      },
    ],
  },
  {
    code: 'N9003',
    accountingDate: '28.03.24',
    treatmentPeriod: '02.24',
    subtotal: '0',
    totalDiscounts: '1507.19',
    vat: '7.7% (A1)',
    rounding: '0.01',
    total: '1623.25',
    client: staticClientsList[1],
    items: [
      {
        art: staticInvoiceArt0.art,
        description: staticInvoiceArt0.description,
        items: [staticInvoiceArt0.items[0]],
      },
    ],
  },
]

export const staticSpendingHistoric = [
  {
    client: 'C15948',
    years: [
      {
        year: 2024,
        total: 260.75,
        data: [
          {
            name: 'Encombrants',
            quantity: 0.24,
            amount: 77.15,
            code: 'EN01',
          },
          {
            name: 'Pneus',
            quantity: 9.08,
            amount: 67.74,
            code: 'PN01',
          },
          {
            name: 'Bois',
            quantity: 0.48,
            amount: 62.4,
            code: 'BO01',
          },
          {
            name: 'Urbains incinérables',
            quantity: 0.18,
            amount: 53.46,
            code: 'UI01',
          },
          {
            name: 'Dégrillage STEP',
            quantity: 0.0,
            amount: 0.0,
            code: 'DS01',
          },
          {
            name: 'Boues de STEP',
            quantity: 0.0,
            amount: 0.0,
            code: 'BS01',
          },
          {
            name: 'Compostables',
            quantity: 0.0,
            amount: 0.0,
            code: 'CO01',
          },
          {
            name: 'Papier-carton',
            quantity: 0.0,
            amount: 0.0,
            code: 'PC01',
          },
          {
            name: 'Ferraille',
            quantity: 0.0,
            amount: 0.0,
            code: 'FE01',
          },
          {
            name: 'Verre',
            quantity: 0.0,
            amount: 0.0,
            code: 'VE01',
          },
          {
            name: 'Aluminium',
            quantity: 0.0,
            amount: 0.0,
            code: 'AL01',
          },
          {
            name: 'Fer-blanc',
            quantity: 0.0,
            amount: 0.0,
            code: 'FB01',
          },
          {
            name: 'Déchets spéciaux des ménages',
            quantity: 0.0,
            amount: 0.0,
            code: 'DSM01',
          },
          {
            name: 'Nespresso',
            quantity: 0.0,
            amount: 0.0,
            code: 'NE01',
          },
          {
            name: 'PET',
            quantity: 0.0,
            amount: 0.0,
            code: 'PE01',
          },
          {
            name: 'Inertes',
            quantity: 0.0,
            amount: 0.0,
            code: 'IN01',
          },
          {
            name: 'Plastiques',
            quantity: 0.0,
            amount: 0.0,
            code: 'PL01',
          },
          {
            name: 'Huiles',
            quantity: 0.0,
            amount: 0.0,
            code: 'HU01',
          },
        ],
      },
      {
        year: 2023,
        total: 12.88,
        data: [
          {
            name: 'Inertes',
            quantity: 0.14,
            amount: 12.88,
            code: 'IN01',
          },
          {
            name: 'Urbains incinérables',
            quantity: 0.0,
            amount: 0.0,
            code: 'UI01',
          },
          {
            name: 'Encombrants',
            quantity: 0.0,
            amount: 0.0,
            code: 'EN01',
          },
          {
            name: 'Dégrillage STEP',
            quantity: 0.0,
            amount: 0.0,
            code: 'DS01',
          },
          {
            name: 'Boues de STEP',
            quantity: 0.0,
            amount: 0.0,
            code: 'BS01',
          },
          {
            name: 'Compostables',
            quantity: 0.0,
            amount: 0.0,
            code: 'CO01',
          },
          {
            name: 'Papier-carton',
            quantity: 0.0,
            amount: 0.0,
            code: 'PC01',
          },
          {
            name: 'Ferraille',
            quantity: 0.0,
            amount: 0.0,
            code: 'FE01',
          },
          {
            name: 'Verre',
            quantity: 0.0,
            amount: 0.0,
            code: 'VE01',
          },
          {
            name: 'Aluminium',
            quantity: 0.0,
            amount: 0.0,
            code: 'AL01',
          },
          {
            name: 'Fer-blanc',
            quantity: 0.0,
            amount: 0.0,
            code: 'FB01',
          },
          {
            name: 'Déchets spéciaux des ménages',
            quantity: 0.0,
            amount: 0.0,
            code: 'DSM01',
          },
          {
            name: 'Nespresso',
            quantity: 0.0,
            amount: 0.0,
            code: 'NE01',
          },
          {
            name: 'PET',
            quantity: 0.0,
            amount: 0.0,
            code: 'PE01',
          },
          {
            name: 'Pneus',
            quantity: 0.0,
            amount: 0.0,
            code: 'PN01',
          },
          {
            name: 'Plastiques',
            quantity: 0.0,
            amount: 0.0,
            code: 'PL01',
          },
          {
            name: 'Bois',
            quantity: 0.0,
            amount: 0.0,
            code: 'BO01',
          },
          {
            name: 'Huiles',
            quantity: 0.0,
            amount: 0.0,
            code: 'HU01',
          },
        ],
      },
    ],
  },
  {
    client: 'C10040',
    years: [
      {
        year: 2024,
        total: 295168.38,
        data: [
          {
            name: 'Urbains incinérables',
            quantity: 619.04,
            amount: 174298.15,
            code: 'UI01',
          },
          {
            name: 'Boues de STEP',
            quantity: 450.05,
            amount: 66606.44,
            code: 'BS01',
          },
          {
            name: 'Compostables',
            quantity: 173.16,
            amount: 28978.26,
            code: 'CO01',
          },
          {
            name: 'Déchets spéciaux des ménages',
            quantity: 0.0,
            amount: 10484.4,
            code: 'DSM01',
          },
          {
            name: 'Dégrillage STEP',
            quantity: 18.68,
            amount: 7750.99,
            code: 'DS01',
          },
          {
            name: 'Verre',
            quantity: 175.05,
            amount: 5251.5,
            code: 'VE01',
          },
          {
            name: 'Plastiques',
            quantity: 7.51,
            amount: 998.83,
            code: 'PL01',
          },
          {
            name: 'Encombrants',
            quantity: 1.66,
            amount: 453.57,
            code: 'EN01',
          },
          {
            name: 'Huiles',
            quantity: 3.3,
            amount: 281.24,
            code: 'HU01',
          },
          {
            name: 'Bois',
            quantity: 0.5,
            amount: 65.0,
            code: 'BO01',
          },
          {
            name: 'Papier-carton',
            quantity: 0.0,
            amount: 0.0,
            code: 'PC01',
          },
          {
            name: 'Ferraille',
            quantity: 0.0,
            amount: 0.0,
            code: 'FE01',
          },
          {
            name: 'Aluminium',
            quantity: 11.09,
            amount: 0.0,
            code: 'AL01',
          },
          {
            name: 'Fer-blanc',
            quantity: 0.0,
            amount: 0.0,
            code: 'FB01',
          },
          {
            name: 'Nespresso',
            quantity: 0.0,
            amount: 0.0,
            code: 'NE01',
          },
          {
            name: 'PET',
            quantity: 0.0,
            amount: 0.0,
            code: 'PE01',
          },
          {
            name: 'Inertes',
            quantity: 0.0,
            amount: 0.0,
            code: 'IN01',
          },
          {
            name: 'Pneus',
            quantity: 0.0,
            amount: 0.0,
            code: 'PN01',
          },
        ],
      },
      {
        year: 2023,
        total: 314842.78,
        data: [
          {
            name: 'Urbains incinérables',
            quantity: 620.09,
            amount: 171381.18,
            code: 'UI01',
          },
          {
            name: 'Boues de STEP',
            quantity: 423.55,
            amount: 62685.4,
            code: 'BS01',
          },
          {
            name: 'Compostables',
            quantity: 181.88,
            amount: 29685.62,
            code: 'CO01',
          },
          {
            name: 'Encombrants',
            quantity: 76.76,
            amount: 20973.32,
            code: 'EN01',
          },
          {
            name: 'Dégrillage STEP',
            quantity: 37.76,
            amount: 13800.02,
            code: 'DS01',
          },
          {
            name: 'Déchets spéciaux des ménages',
            quantity: 0.0,
            amount: 9790.8,
            code: 'DSM01',
          },
          {
            name: 'Verre',
            quantity: 169.88,
            amount: 5096.4,
            code: 'VE01',
          },
          {
            name: 'Plastiques',
            quantity: 7.73,
            amount: 1028.09,
            code: 'PL01',
          },
          {
            name: 'Huiles',
            quantity: 3.36,
            amount: 239.75,
            code: 'HU01',
          },
          {
            name: 'Bois',
            quantity: 1.42,
            amount: 184.6,
            code: 'BO01',
          },
          {
            name: 'Ferraille',
            quantity: 0.0,
            amount: 0.0,
            code: 'FE01',
          },
          {
            name: 'Aluminium',
            quantity: 10.74,
            amount: 0.0,
            code: 'AL01',
          },
          {
            name: 'Fer-blanc',
            quantity: 0.0,
            amount: 0.0,
            code: 'FB01',
          },
          {
            name: 'Nespresso',
            quantity: 0.0,
            amount: 0.0,
            code: 'NE01',
          },
          {
            name: 'PET',
            quantity: 0.0,
            amount: 0.0,
            code: 'PE01',
          },
          {
            name: 'Inertes',
            quantity: 0.0,
            amount: 0.0,
            code: 'IN01',
          },
          {
            name: 'Pneus',
            quantity: 0.0,
            amount: 0.0,
            code: 'PN01',
          },
          {
            name: 'Papier-carton',
            quantity: 0.32,
            amount: -22.4,
            code: 'PC01',
          },
        ],
      },
    ],
  },
]

// TODO this is to be static of to be fetched from the API
export const staticCategoryOfProducts = [
  { value: '0', label: 'all_categories' },
  { value: '100', label: 'burnable_urban_waste' },
  { value: '101', label: 'bulky_waste' },
  { value: '102', label: 'step_screening' },
  { value: '103', label: 'step_sludge' },
  { value: '105', label: 'compostables' },
  { value: '106', label: 'paper_board' },
  { value: '107', label: 'scrap_metal' },
  { value: '108', label: 'glass' },
  { value: '109', label: 'aluminum' },
  { value: '110', label: 'cans' },
  { value: '111', label: 'household_special_waste' },
  { value: '112', label: 'nespresso' },
  { value: '113', label: 'pet' },
  { value: '115', label: 'inert' },
  { value: '118', label: 'tires' },
  { value: '120', label: 'plastics' },
  { value: '121', label: 'wood' },
  { value: '140', label: 'oils' },
]

export const staticCategoryHistory = [
  {
    nProduct: 1001,
    productName: 'Urbains incinérables TS franco VD190611',
    date: '19.02.24',
    quantity: 0.66,
    amount: 166.62,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1001,
    productName: 'Urbains incinérables TS franco VD190611',
    date: '26.02.24',
    quantity: 0.38,
    amount: 95.93,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1031,
    productName:
      'Collecte porte à porte, transport et traitement déchets non taxés Collège de la Cité',
    date: '29.02.24',
    quantity: 0.098,
    amount: 28.82,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1031,
    productName:
      'Collecte porte à porte, transport et traitement déchets non taxés Déchetterie-Industrie',
    date: '29.02.24',
    quantity: 0.244,
    amount: 71.76,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1030,
    productName:
      'Collecte porte à porte, transport et traitement sacs taxés Administration Communale',
    date: '29.02.24',
    quantity: 0.408,
    amount: 119.99,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1031,
    productName:
      'Collecte porte à porte, transport et traitement déchets non taxés Collège du Verneret',
    date: '29.02.24',
    quantity: 0.474,
    amount: 139.4,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1031,
    productName: 'Collecte porte à porte, transport et traitement déchets non taxés Voirie',
    date: '29.02.24',
    quantity: 1.544,
    amount: 454.09,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1031,
    productName:
      'Collecte porte à porte, transport et traitement déchets non taxés Couches-culottes',
    date: '29.02.24',
    quantity: 3.61,
    amount: 1061.7,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1030,
    productName: 'Collecte porte à porte, transport et traitement sacs taxés Chavornay',
    date: '29.02.24',
    quantity: 16.206,
    amount: 4766.18,
    invoiceNumber: 'F172148',
  },
  {
    nProduct: 1071,
    productName: 'OM TS - traitement franco gare',
    date: '29.02.24',
    quantity: 26.64,
    amount: 4528.8,
    invoiceNumber: 'F172148',
  },
]

export const staticChartComparisonAnnualAmounts = {
  categories: [
    { label: 'burnable_urban_waste', color: { r: 243, g: 112, b: 97 } },
    { label: 'bulky_waste', color: { r: 161, g: 192, b: 108 } },
    { label: 'step_screening', color: { r: 224, g: 185, b: 143 } },
    { label: 'step_sludge', color: { r: 188, g: 138, b: 212 } },
    { label: 'compostables', color: { r: 229, g: 177, b: 84 } },
    { label: 'paper_board', color: { r: 126, g: 188, b: 221 } },
    { label: 'scrap_metal', color: { r: 219, g: 154, b: 150 } },
    { label: 'glass', color: { r: 224, g: 123, b: 146 } },
    { label: 'aluminum', color: { r: 181, g: 181, b: 207 } },
    { label: 'cans', color: { r: 139, g: 184, b: 160 } },
    { label: 'household_special_waste', color: { r: 184, g: 205, b: 144 } },
    { label: 'nespresso', color: { r: 228, g: 144, b: 184 } },
    { label: 'pet', color: { r: 171, g: 198, b: 218 } },
    { label: 'inert', color: { r: 215, g: 134, b: 143 } },
    { label: 'tires', color: { r: 132, g: 177, b: 198 } },
    { label: 'plastics', color: { r: 192, g: 137, b: 174 } },
    { label: 'wood', color: { r: 242, g: 163, b: 140 } },
    { label: 'oils', color: { r: 205, g: 195, b: 111 } },
  ],
  data: generateRandomChartDataByYears(2008, 2024, 18), // Generate random data from 2008 to 2024 for 18 categories
}

export const staticChartRecycle = {
  categories: [
    { label: 'glass', color: { r: 224, g: 123, b: 146 } },
    { label: 'wood', color: { r: 242, g: 163, b: 140 } },
    { label: 'aluminum', color: { r: 181, g: 181, b: 207 } },
    { label: 'oils', color: { r: 205, g: 195, b: 111 } },
    { label: 'cans', color: { r: 139, g: 184, b: 160 } },
    { label: 'services', color: { r: 243, g: 112, b: 97 } },
    { label: 'paper_board', color: { r: 126, g: 188, b: 221 } },
    { label: 'compostables', color: { r: 229, g: 177, b: 84 } },
    { label: 'bulky_waste', color: { r: 161, g: 192, b: 108 } },
    { label: 'burnable_urban_waste', color: { r: 224, g: 185, b: 143 } },
  ],
  data: generateRandomChartDataByYears(2008, 2024, 10), // Generate random data from 2008 to 2024 for 18 categories
}

function generateRandomChartDataByYears(startYear, endYear, numCategories) {
  let data = []
  for (let year = startYear; year <= endYear; year++) {
    let yearData = {}
    yearData[year] = []
    for (let i = 0; i < numCategories; i++) {
      // Generate a random number between 20,000 and 60,000 for example purposes
      let randomValue = Math.floor(Math.random() * (60000 - 20000 + 1)) + 20000
      yearData[year].push(randomValue.toString())
    }
    data.push(yearData)
  }
  return data
}
