// Key for storing authentication data in local storage
const STORAGE_KEY = 'auth_clients'

class AuthClientsStorageManager {
  // Save mange auth clients data to be saved on local storage
  static save(data): boolean {
    try {
      this.persist({ data: data, timestamp: new Date() })
      return true
    } catch (error) {
      return false
    }
  }

  // persist data to local storage
  private static persist(data: object): void {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data))
  }

  // Retrieve auth clients data from local storage
  static get(): JSON | null {
    const data = localStorage.getItem(STORAGE_KEY)
    if (data) {
      return JSON.parse(data)
    }
    return null
  }

  // Clear all data from local storage
  static clean(): void {
    localStorage.removeItem(STORAGE_KEY)
  }
}

export default AuthClientsStorageManager
